import React from "react"
import { graphql } from "gatsby"
import PageTypes from "../../components/page-types/_PageTypes"
import Blocks from "gatsby-theme-yum-components/src/components/blocks/_Blocks"

const StoryblokDefaultTemplate = ({ data, venue, location }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const navigationNode = data.navigation
  const navigation = navigationNode ? JSON.parse(navigationNode.content) : {}

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  //   console.log("Storyblok Content", content)

  return React.createElement(
    PageTypes(content.component),
    {
      key: node.uid,
      content: content,
      name: node.name,
      type: content.component,
      navigation: navigation,
      venue: venue,
      location: location,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query StoryblokDefaultTemplateQuery($id: String!) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
    }
    navigation: storyblokEntry(full_slug: { eq: "global-navigation" }) {
      content
    }
  }
`

export default StoryblokDefaultTemplate
